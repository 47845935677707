"use client";

import { Button, Card, Wobble } from "@open/hoose/ui";
import { Avatar, AvatarFallback } from "@open/hoose/ui/avatar";
import { CardTitle } from "@open/hoose/ui/card";
import { useFetchUserOrgs } from "app/_data-hooks/useFetchUserOrgs";
import { useLinkHirarcy } from "app/_hooks/useLinkHirarcy";
import { EmptyBlock } from "components/domain/EmptyBlock";
import { Link } from "lib/router-events";
import { cn } from "lib/utils";
import { signIn } from "next-auth/react";
import React, { useState } from "react";
import { EmptyState } from "./EmptyState";
import { PageContainer } from "./PageContainer";
import { AuthProviderU } from "lib/auth.types";

function getInitials(name: string, max = 2) {
  return name
    .split(" ")
    .map((n) => n[0])
    .join("")
    .slice(0, max);
}

export function SelectOrganization() {
  const { data: orgs, isLoading } = useFetchUserOrgs();
  const links = useLinkHirarcy();
  const [loading, setLoading] = useState(false);

  const handleOrgChange = async (orgId: string) => {
    setLoading(true);
    await signIn("switch-org" satisfies AuthProviderU, {
      orgId,
      redirect: true,
      callbackUrl: "/",
    });
    setLoading(false);
  };

  return (
    <PageContainer
      heading="Select an organization"
      actions={
        <>
          <Button asChild variant="secondary" disabled={loading}>
            <Link href={links.auth.signout}>Logout</Link>
          </Button>
          <Button asChild disabled={loading}>
            <Link href={links.org.create}>Create Organization</Link>
          </Button>
        </>
      }
      isLoading={isLoading}
    >
      {!orgs || orgs.length === 0 ? (
        <EmptyState
          title="No organizations created yet"
          description="Create your first organization to get started."
          action={
            <Button asChild disabled={loading}>
              <Link href={links.org.create}>Create Organization</Link>
            </Button>
          }
        />
      ) : (
        orgs.map((org) => (
          <Wobble key={org.id}>
            <Card
              className={cn(
                "cursor-pointer",
                loading && "opacity-50 pointer-events-none",
              )}
              onClick={() => !loading && handleOrgChange(org.id)}
            >
              <div className="flex items-center gap-2">
                <Avatar bg="primary">
                  <AvatarFallback>{getInitials(org.name)}</AvatarFallback>
                </Avatar>
                <CardTitle>{org.name}</CardTitle>
              </div>
            </Card>
          </Wobble>
        ))
      )}
    </PageContainer>
  );
}
