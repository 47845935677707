import { SubSection } from "enums/sub-section.enum";
import { useSelectedLayoutSegments } from "next/navigation";
import { useMemo } from "react";
import { MainSection } from "../../enums/main-section.enum";

export function useActiveSection() {
  const segments = useSelectedLayoutSegments();

  const activeMainSection: MainSection | null = useMemo(() => {
    // // Phone -- before (channels)
    // if (segments?.includes("phone")) return MainSection.phone;
    // Inbox
    if (segments?.includes("(inbox)")) return MainSection.inbox;
    // Channels
    if (segments?.includes("(channels)")) return MainSection.channels;
    // AI Outbound
    if (segments?.includes("(emails)")) return MainSection.aiOutbound;
    if (segments?.includes("(sequencing)")) return MainSection.aiOutbound;
    // Contacts
    if (segments?.includes("(contacts)")) return MainSection.contacts;
    // Reports
    if (segments?.includes("(reports)")) return MainSection.reports;
    // Help Center
    if (segments?.includes("(help-center)")) return MainSection["help-center"];
    // Settings
    if (segments?.includes("(settings)")) return MainSection.settings;
    // AI Abilities
    if (segments?.includes("(actions)")) return MainSection.aiEmployee;
    if (segments?.includes("(ai-knowledge)")) return MainSection.aiEmployee;
    if (segments?.includes("(training)")) return MainSection.aiEmployee;
    // Workflows
    if (segments?.includes("(workflows)")) return MainSection.workflows;
    return null;
  }, [segments]);

  const activeSubSection: SubSection | null = useMemo(() => {
    if (segments?.includes("inbox")) return SubSection.INBOX;
    if (segments?.includes("channels")) {
      if (segments?.includes("widget")) return SubSection.CHANNELS__WEB;
      if (segments?.includes("phone")) return SubSection.CHANNELS__PHONE;
      if (segments?.includes("mail")) return SubSection.CHANNELS__EMAIL;
      if (segments?.includes("slack")) return SubSection.CHANNELS__SLACK;
      if (segments?.includes("sms")) return SubSection.CHANNELS__SMS;
      if (segments?.includes("whatsapp")) return SubSection.CHANNELS__WHATSAPP;
      return SubSection.CHANNELS;
    }

    if (segments?.includes("seq")) return SubSection.SEQUENCING;
    if (segments?.includes("e")) return SubSection.EMAILS;

    if (segments?.includes("contacts")) return SubSection.CONTACTS;

    if (segments?.includes("reports")) {
      if (segments?.includes("ai-insights"))
        return SubSection.REPORTS__AI_INSIGHTS;
      if (segments?.includes("voice-of-customer"))
        return SubSection.REPORTS__VOICE_OF_CUSTOMER;
      if (segments?.includes("handoffs")) return SubSection.REPORTS__HANDOFFS;
      if (segments?.includes("human-reporting"))
        return SubSection.REPORTS__HUMAN_REPORTING;
      if (segments?.includes("data-export"))
        return SubSection.REPORTS__DATA_EXPORT;
      return SubSection.REPORTS;
    }

    if (segments?.includes("settings")) {
      if (segments?.includes("context")) return SubSection.SETTINGS__AI_PROFILE;
      if (segments?.includes("autopilot"))
        return SubSection.SETTINGS__AI_AUTOPILOT;
      if (segments?.includes("evals"))
        return SubSection.SETTINGS__AI_PERFORMANCE_CHECKS;
      if (segments?.includes("domains")) return SubSection.SETTINGS__DOMAINS;
      if (segments?.includes("integrations"))
        return SubSection.SETTINGS__INTEGRATIONS;
      if (segments?.includes("office-hours"))
        return SubSection.SETTINGS__OFFICE_HOURS;
      if (segments?.includes("access")) return SubSection.SETTINGS__API_TOKENS;
      if (segments?.includes("tags")) return SubSection.SETTINGS__ORG_TAGS;
      if (segments?.includes("groups")) return SubSection.SETTINGS__GROUPS;
      if (segments?.includes("appearance"))
        return SubSection.SETTINGS__APPEARANCE;
      if (segments?.includes("teammates"))
        return SubSection.SETTINGS__TEAM_MEMBERS;
      if (segments?.includes("webhooks")) return SubSection.SETTINGS__WEBHOOKS;
      if (segments?.includes("security")) return SubSection.SETTINGS__SECURITY;
      if (segments?.includes("handoff")) return SubSection.SETTINGS__HANDOFF;
      if (segments?.includes("ticketing"))
        return SubSection.SETTINGS__TICKETING;
      if (segments.includes("templates")) return SubSection.EMAIL_TEMPLATES;
      if (segments.includes("knowledgebase"))
        return SubSection.SETTINGS__KNOWLEDGEBASE;
      if (segments.includes("phone-numbers"))
        return SubSection.SETTINGS__PHONE_NUMBERS;
      if (segments.includes("agent-assignment"))
        return SubSection.SETTINGS__AGENT_ASSIGNMENT;
      // This one should be the last one
      if (segments?.includes("settings")) return SubSection.SETTINGS__GENERAL;
      if (segments?.includes("variables"))
        return SubSection.SETTINGS__VARIABLES;
    }

    if (segments?.includes("workflows")) return SubSection.WORKFLOWS;

    if (segments?.includes("actions")) return SubSection.AI__ACTIONS;
    if (segments?.includes("ai-knowledge")) return SubSection.AI__KNOWLEDGE;
    if (segments?.includes("training")) return SubSection.AI__CUSTOM_TRAINING;

    return null;
  }, [segments]);

  return {
    activeMainSection: activeMainSection,
    activeSubSection: activeSubSection,
    segments,
  };
}
