"use client";

import { toast } from "@open/hoose/ui/sonner";
import { CopySimple } from "@phosphor-icons/react";
import { useOrgData } from "app/_data-providers/OrgDataProvider";
import { Shortcut } from "enums/shortcut.enum";
import { useHotkey } from "hooks/useHotkey";
import { AuthProviderU } from "lib/auth.types";
import { IS_SERVER } from "lib/env";
import { signIn } from "next-auth/react";
import { usePathname, useSearchParams } from "next/navigation";
import { useCallback, useEffect } from "react";

export function HandleUrlCopy() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const currentOrganization = useOrgData();

  const handleCopyUrl = useCallback(() => {
    try {
      const url = new URL(pathname, window.location.origin);

      // Convert searchParams to URLSearchParams since it's read-only
      const params = new URLSearchParams(searchParams.toString());

      if (currentOrganization?.id) {
        params.set("org", currentOrganization.id);
      }

      url.search = params.toString();

      navigator.clipboard
        .writeText(url.toString())
        .then(() => {
          toast.success("Current URL copied to clipboard", {
            description: url.toString(),
          });
        })
        .catch((error) => {
          console.error("Failed to copy URL:", error);
          toast.error("Failed to copy URL to clipboard");
        });
    } catch (error) {
      console.error("Error handling URL copy:", error);
      toast.error("Failed to process URL");
    }
  }, [pathname, searchParams, currentOrganization]);

  useHotkey(
    [Shortcut["alt+c"]],
    handleCopyUrl,
    {
      description: "Copy current URL",
      icon: <CopySimple />,
    },
    [handleCopyUrl],
  );

  const handleOrgSwitch = useCallback(async () => {
    const orgId = searchParams.get("org");

    if (!orgId || !currentOrganization) return;

    if (currentOrganization.id !== orgId) {
      try {
        const searchParamsWithoutOrg = new URLSearchParams(
          searchParams.toString(),
        );
        searchParamsWithoutOrg.delete("org");

        const url = new URL(pathname, window.location.origin);
        url.search = searchParamsWithoutOrg.toString();

        await signIn("switch-org" satisfies AuthProviderU, {
          orgId,
          redirect: true,
          callbackUrl: url.toString(),
        });
      } catch (error) {
        console.error("Error switching organization:", error);
        toast.error("Failed to switch organization");
      }
    }
  }, [searchParams, currentOrganization, pathname]);

  useEffect(() => {
    if (searchParams.has("org")) {
      handleOrgSwitch();
    }
  }, [searchParams, handleOrgSwitch]);

  return null;
}
