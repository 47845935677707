import {
  BookOpenText,
  Books,
  ChartDonut,
  ChatsTeardrop,
  CirclesFour,
  CubeTransparent,
  DotsThreeOutline,
  FileArrowDown,
  Gear,
  Globe,
  Icon,
  Lightning,
  MagicWand,
  PaperPlaneRight,
  Phone,
  Sparkle,
  Tray,
  TreeStructure,
  Users,
  UsersThree,
  WhatsappLogo,
} from "@phosphor-icons/react";
import { useActiveSection } from "app/_hooks/useActiveSection";
import { useLinkHirarcy } from "app/_hooks/useLinkHirarcy";
import { MainSection } from "enums/main-section.enum";
import { SubSection } from "enums/sub-section.enum";
import {
  BotIcon,
  BotMessageSquare,
  BotOff,
  BrainCircuitIcon,
  BrushIcon,
  CableIcon,
  Cctv,
  ClockIcon,
  Flashlight,
  HandshakeIcon,
  HelpCircle,
  KeyRoundIcon,
  LinkIcon,
  LucideIcon,
  MailsIcon,
  MessageSquare,
  MessageSquareWarning,
  Rabbit,
  Settings2Icon,
  ShieldCheckIcon,
  Slack,
  TagIcon,
  Ticket,
  UserIcon,
  UserRoundPen,
  Users2Icon,
  Workflow,
  Zap,
} from "lucide-react";
import { ReactNode } from "react";

export type MainSectionDetails = {
  id: MainSection;
  icon: Icon;
  label: string;
  url: string;
  isActive: boolean;
  sections: Array<{
    id: string;
    icon: LucideIcon | Icon;
    label: string;
    url: string;
    isActive: boolean;
  }>;
};

export function useNavLinks() {
  const links = useLinkHirarcy();
  const { activeMainSection, activeSubSection } = useActiveSection();

  const mainNav = [
    {
      id: MainSection.inbox,
      icon: ChatsTeardrop,
      label: "Inbox",
      url: links.org.inbox.index,
      isActive: activeMainSection === MainSection.inbox,
      sections: [],
    },
    {
      id: MainSection.aiOutbound,
      icon: TreeStructure,
      label: "Outbound",
      url: links.org.sequencing.index,
      isActive: activeMainSection === MainSection.aiOutbound,
      sections: [],
    },
    {
      id: MainSection.contacts,
      icon: Users,
      label: "Contacts",
      url: links.org.contacts.index,
      isActive: activeMainSection === MainSection.contacts,
      sections: [],
    },
    {
      id: MainSection.channels,
      icon: CirclesFour,
      label: "AI Agents",
      url: links.org.channels.index,
      isActive: activeMainSection === MainSection.channels,
      sections: [
        {
          id: "channels:web",
          icon: Globe,
          label: "Web",
          url: links.org.channels.configure.web,
          isActive: activeSubSection === SubSection.CHANNELS__WEB,
        },
        {
          id: "channels:phone",
          icon: Phone,
          label: "Phone",
          url: links.org.channels.phone.index,
          isActive: activeSubSection === SubSection.CHANNELS__PHONE,
        },
        {
          id: "channels:email",
          icon: MailsIcon,
          label: "Email",
          url: links.org.channels.configure.email,
          isActive: activeSubSection === SubSection.CHANNELS__EMAIL,
        },
        {
          id: "channels:slack",
          icon: Slack,
          label: "Slack",
          url: links.org.channels.slack,
          isActive: activeSubSection === SubSection.CHANNELS__SLACK,
        },
        {
          id: "channels:sms",
          icon: MessageSquare,
          label: "SMS",
          url: links.org.channels.sms.index,
          isActive: activeSubSection === SubSection.CHANNELS__SMS,
        },
        {
          id: "channels:whatsapp",
          icon: WhatsappLogo,
          label: "WhatsApp",
          url: links.org.channels.whatsapp.index,
          isActive: activeSubSection === SubSection.CHANNELS__WHATSAPP,
        },
      ],
    },
    {
      id: MainSection.aiEmployee,
      icon: Sparkle,
      label: "AI Training ",
      url: links.org.inbox.index,
      isActive: activeMainSection === MainSection.aiEmployee,
      sections: [
        {
          icon: Lightning,
          label: "AI Actions",
          url: links.org.actions.index,
          id: "actions",
          isActive: activeSubSection === SubSection.AI__ACTIONS,
        },
        {
          icon: CubeTransparent,
          label: "AI Knowledge",
          url: links.org.knowledge.index,
          id: "knowledge-base",
          isActive: activeSubSection === SubSection.AI__KNOWLEDGE,
        },
        {
          icon: BotIcon,
          label: "AI Autopilot",
          url: links.org.settings.aiAutopilot,
          id: "autopilot",
          isActive: false, // focused in settings nav
        },
      ],
    },
  ] satisfies MainSectionDetails[];

  const settingsNav = {
    id: MainSection.settings,
    icon: Gear,
    label: "Settings",
    url: links.org.settings.index,
    isActive: activeMainSection === MainSection.settings,
    sections: [
      {
        id: "settings:general",
        icon: Settings2Icon,
        label: "General",
        url: links.org.settings.index,
        isActive: activeSubSection === SubSection.SETTINGS__GENERAL,
      },
      {
        id: "settings:context",
        icon: BrainCircuitIcon,
        label: "AI Profile",
        url: links.org.settings.context,
        isActive: activeSubSection === SubSection.SETTINGS__AI_PROFILE,
      },
      {
        id: "settings:ai-autopilot",
        icon: BotMessageSquare,
        label: "AI Autopilot",
        url: links.org.settings.aiAutopilot,
        isActive: activeSubSection === SubSection.SETTINGS__AI_AUTOPILOT,
      },
      {
        id: "settings:ai-evals",
        icon: Lightning,
        label: "AI Evaluations",
        url: links.org.settings.aiEvals,
        isActive:
          activeSubSection === SubSection.SETTINGS__AI_PERFORMANCE_CHECKS,
      },
      {
        id: "settings:domains",
        icon: LinkIcon,
        label: "Domains",
        url: links.org.settings.domains,
        isActive: activeSubSection === SubSection.SETTINGS__DOMAINS,
      },
      {
        id: "settings:integrations",
        icon: CableIcon,
        label: "Integrations",
        url: links.org.settings.integrations,
        isActive: activeSubSection === SubSection.SETTINGS__INTEGRATIONS,
      },
      {
        id: "settings:office-hours",
        icon: ClockIcon,
        label: "Office Hours",
        url: links.org.settings.officeHours,
        isActive: activeSubSection === SubSection.SETTINGS__OFFICE_HOURS,
      },
      {
        id: "settings:access",
        icon: KeyRoundIcon,
        label: "API Tokens",
        url: links.org.settings.accessToken,
        isActive: activeSubSection === SubSection.SETTINGS__API_TOKENS,
      },
      {
        id: "settings:tags",
        icon: TagIcon,
        label: "Organization Tags",
        url: links.org.settings.tags,
        isActive: activeSubSection === SubSection.SETTINGS__ORG_TAGS,
      },
      {
        id: "settings:groups",
        icon: UsersThree,
        label: "Teams",
        url: links.org.settings.groups,
        isActive: activeSubSection === SubSection.SETTINGS__GROUPS,
      },
      {
        id: "settings:agent-assignment",
        icon: UsersThree,
        label: "Human Agent Assignment",
        url: links.org.settings.agentAssignment,
        isActive: activeSubSection === SubSection.SETTINGS__AGENT_ASSIGNMENT,
      },
      {
        id: "settings:knowledgebase",
        icon: HelpCircle,
        label: "Knowledgebase",
        url: links.org.settings.knowledgebase,
        isActive: activeSubSection === SubSection.SETTINGS__KNOWLEDGEBASE,
      },
      {
        id: "settings:phone-numbers",
        icon: Phone,
        label: "Phone Numbers",
        url: links.org.settings.phoneNumbers,
        isActive: activeSubSection === SubSection.SETTINGS__PHONE_NUMBERS,
      },
      {
        id: "settings:webhooks",
        icon: CableIcon,
        label: "Webhooks",
        url: links.org.settings.webhooks,
        isActive: activeSubSection === SubSection.SETTINGS__WEBHOOKS,
      },
      {
        id: "settings:handoff",
        icon: HandshakeIcon,
        label: "Handoff",
        url: links.org.settings.handoff,
        isActive: activeSubSection === SubSection.SETTINGS__HANDOFF,
      },
      {
        id: "settings:ticketing",
        icon: Ticket,
        label: "Ticketing",
        url: links.org.settings.ticketing,
        isActive: activeSubSection === SubSection.SETTINGS__TICKETING,
      },
      {
        id: "settings:security",
        icon: ShieldCheckIcon,
        label: "Security",
        url: links.org.settings.security,
        isActive: activeSubSection === SubSection.SETTINGS__SECURITY,
      },
      {
        id: "settings:teammates",
        icon: Users2Icon,
        label: "Team Members",
        url: links.org.settings.teammates,
        isActive: activeSubSection === SubSection.SETTINGS__TEAM_MEMBERS,
      },
      {
        id: "settings:variables",
        icon: KeyRoundIcon,
        label: "Variables",
        url: links.org.settings.variables,
        isActive: activeSubSection === SubSection.SETTINGS__VARIABLES,
      },
    ],
  } satisfies MainSectionDetails;

  const reportsNav = {
    id: MainSection.reports,
    icon: ChartDonut,
    label: "Reports",
    url: links.org.reports.index,
    isActive: activeMainSection === MainSection.reports,
    sections: [
      {
        id: "reports:index",
        icon: ChartDonut,
        label: "Impact dashboard",
        url: links.org.reports.index,
        isActive: activeSubSection === SubSection.REPORTS,
      },
      {
        id: "reports:voice-of-customer",
        icon: MagicWand,
        label: "Customer Insights",
        url: links.org.reports.voiceOfCustomer,
        isActive: activeSubSection === SubSection.REPORTS__VOICE_OF_CUSTOMER,
      },
      {
        id: "reports:ai-insights",
        icon: Cctv,
        label: "Recommendations",
        url: links.org.reports.aiInsights,
        isActive: activeSubSection === SubSection.REPORTS__AI_INSIGHTS,
      },
      {
        id: "reports:handoffs",
        icon: HandshakeIcon,
        label: "Handoffs",
        url: links.org.reports.handoffs,
        isActive: activeSubSection === SubSection.REPORTS__HANDOFFS,
      },
      {
        id: "reports:human-reporting",
        icon: Users,
        label: "Human",
        url: links.org.reports.humanReporting,
        isActive: activeSubSection === SubSection.REPORTS__HUMAN_REPORTING,
      },
      {
        id: "reports:data-export",
        icon: FileArrowDown,
        label: "Data Export",
        url: links.org.reports.dataExport,
        isActive: activeSubSection === SubSection.REPORTS__DATA_EXPORT,
      },
    ],
  } satisfies MainSectionDetails;

  const channelsNav = {
    id: MainSection.channels,
    icon: CirclesFour,
    label: "Channels",
    url: links.org.channels.index,
    isActive: activeMainSection === MainSection.channels,
    sections: [
      {
        id: "channels:web",
        icon: Globe,
        label: "Web",
        url: links.org.channels.configure.web,
        isActive: activeSubSection === SubSection.CHANNELS__WEB,
      },
      {
        id: "channels:phone",
        icon: Phone,
        label: "Phone",
        url: links.org.channels.phone.index,
        isActive: activeSubSection === SubSection.CHANNELS__PHONE,
      },
      {
        id: "channels:email",
        icon: MailsIcon,
        label: "Email",
        url: links.org.channels.configure.email,
        isActive: activeSubSection === SubSection.CHANNELS__EMAIL,
      },
      {
        id: "channels:slack",
        icon: Slack,
        label: "Slack",
        url: links.org.channels.slack,
        isActive: activeSubSection === SubSection.CHANNELS__SLACK,
      },
      {
        id: "channels:sms",
        icon: MessageSquare,
        label: "SMS",
        url: links.org.channels.sms.index,
        isActive: activeSubSection === SubSection.CHANNELS__SMS,
      },
    ],
  } satisfies MainSectionDetails;

  const helpCentersNav = {
    id: MainSection["help-center"],
    icon: BookOpenText,
    label: "Help Centers",
    url: links.org.helpCenter.index,
    isActive: activeMainSection === MainSection["help-center"],
    sections: [],
  } satisfies MainSectionDetails;

  const aiEmployeeNav = {
    id: MainSection.aiEmployee,
    icon: Sparkle,
    label: "AI Training & Behavior",
    url: links.org.inbox.index,
    isActive: activeMainSection === MainSection.aiEmployee,
    sections: [
      {
        icon: Lightning,
        label: "AI Actions",
        url: links.org.actions.index,
        id: "actions",
        isActive: activeSubSection === SubSection.AI__ACTIONS,
      },
      {
        icon: CubeTransparent,
        label: "AI Knowledge",
        url: links.org.knowledge.index,
        id: "knowledge-base",
        isActive: activeSubSection === SubSection.AI__KNOWLEDGE,
      },
      {
        icon: BotIcon,
        label: "AI Autopilot",
        url: links.org.settings.aiAutopilot,
        id: "autopilot",
        isActive: false, // focused in settings nav
      },
    ],
  } satisfies MainSectionDetails;

  const workflowsNav = {
    id: MainSection.workflows,
    icon: Workflow,
    label: "Workflows",
    url: links.org.workflows.index,
    isActive: activeMainSection === MainSection.workflows,
    sections: [],
  } satisfies MainSectionDetails;

  return {
    mainNav,
    settingsNav,
    reportsNav,
    // aiEmployeeNav,
    // channelsNav,
    helpCentersNav,
    workflowsNav,
  };
}
