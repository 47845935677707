"use client";
import { ShortcutKbd } from "@open/hoose/ui";
import { Badge } from "@open/hoose/ui/badge";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@open/hoose/ui/collapsible";
import {
  SidebarContent,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  useSidebar,
} from "@open/hoose/ui/sidebar";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { useShortcutsRegistry } from "app/_data-providers/ClientifiedHotkeysProvider";
import { useOrgData } from "app/_data-providers/OrgDataProvider";
import { ClientOnly } from "components/headless/ClientOnly";
import { MainSection } from "enums/main-section.enum";
import { Shortcut } from "enums/shortcut.enum";
import { Link, useRouter } from "lib/router-events";
import { cn } from "lib/utils";
import { ChevronRight, Sparkles } from "lucide-react";
import { Dispatch, SetStateAction, useState } from "react";
import { MotionDiv } from "../MotionDiv";
import { Tooltip } from "../Tooltip";
import { MainSectionDetails, useNavLinks } from "./useNavLinks";

const ALLOWED_ORG_IDS = [
  "23ab3726-187f-4c11-83e9-0fb3ccabd6f4",
  "8f7d3b2a-5e1c-4f9d-b8a2-9c4e6d8f7a3b",
  "2c9e4b8a-1d7f-4e6c-9b3a-8f5d2e1c7b4a",
  "6b4a2e8d-9c5f-4a3b-8e7d-1f2c4b6a9d3e",
  "4d7e2f9c-3b8a-5d1e-7f4c-2e9b6a8d4f1c",
] satisfies string[];

function AppSidebar__Content__Cmdk() {
  const { state } = useSidebar();
  const { registry } = useShortcutsRegistry();

  return (
    <SidebarMenuButton
      // for some reason, the tooltip is not working here
      // tooltip={<span>{app.label}</span>}
      className={cn(
        "cursor-pointer",
        state === "expanded" &&
          "bg-background/50 outline outline-1 outline-border",
      )}
      onClick={() =>
        registry
          .get(Shortcut["command+k"])
          ?.callback(new KeyboardEvent("keydown"), {
            hotkey: Shortcut["command+k"],
          })
      }
    >
      <MagnifyingGlass />
      <span className="whitespace-nowrap font-medium">Search</span>
      <ClientOnly>
        <MotionDiv className="ml-auto">
          <ShortcutKbd
            className={cn(
              state === "expanded" ? "opacity-100" : "opacity-0",
              "transition",
            )}
          >
            {Shortcut["command+k"]}
          </ShortcutKbd>
        </MotionDiv>
      </ClientOnly>
    </SidebarMenuButton>
  );
}

function AppSidebar__Content__MenuItem({
  app,
  noSubItems = false,
  expandedSection,
  setExpandedSection,
  flag,
}: {
  app: MainSectionDetails;
  noSubItems?: boolean;
  expandedSection: MainSection | null;
  setExpandedSection: Dispatch<SetStateAction<MainSection | null>>;
  flag?: "alpha" | "beta";
}) {
  const { state } = useSidebar();
  return (
    <Collapsible
      key={app.id}
      asChild
      defaultOpen={app.isActive}
      className="group/collapsible"
      open={expandedSection === app.id}
      onOpenChange={(open) => {
        if (open) {
          setExpandedSection(app.id);
        } else {
          setExpandedSection(null);
        }
      }}
    >
      <SidebarMenuItem>
        <Tooltip
          content={state === "expanded" ? undefined : app.label}
          side="right"
        >
          <CollapsibleTrigger asChild>
            <SidebarMenuButton
              // for some reason, the tooltip is not working here
              // tooltip={<span>{app.label}</span>}
              className={cn(
                "cursor-pointer",
                app.isActive &&
                  "bg-background/50 outline outline-1 outline-border text-primary",
              )}
              asChild
            >
              <Link
                href={state === "collapsed" || noSubItems ? app.url : undefined}
                className="font-medium [&_*]:font-medium"
              >
                {app.icon && <app.icon />}
                <span className="whitespace-nowrap font-medium">
                  {app.label}
                </span>
                {flag && (
                  <Badge variant="info-outline" className="ml-auto">
                    {flag}
                  </Badge>
                )}
                {!noSubItems && (
                  <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                )}
              </Link>
            </SidebarMenuButton>
          </CollapsibleTrigger>
        </Tooltip>
        {!noSubItems && (
          <CollapsibleContent>
            <SidebarMenuSub
              data-state={state === "expanded" ? "open" : "closed"}
              className={cn(
                // Nullify the default style of hiding the whole section
                "group-data-[collapsible=icon]:flex",
                "h-[--radix-collapsible-content-height] overflow-hidden transition-[height,padding]",
                // "transition-all overflow-hidden",
                "pt-1",
                state === "collapsed" && "h-0 p-0",
              )}
            >
              {app.sections?.map((section) => (
                <SidebarMenuSubItem key={section.id} className="shrink-0">
                  <SidebarMenuSubButton
                    asChild
                    className={cn(
                      // Nullify the default style of hiding the whole section
                      "group-data-[collapsible=icon]:flex",
                      "[&>svg]:text-inherit",
                      "text-muted-foreground",
                      section.isActive &&
                        "text-primary bg-background/50 outline outline-1 outline-border rounded-[0.625rem]",
                    )}
                  >
                    <Link
                      href={section.url}
                      className="font-medium [&_*]:font-medium"
                    >
                      {section.icon && <section.icon className="" />}
                      <span className="whitespace-nowrap font-medium">
                        {section.label}
                      </span>
                    </Link>
                  </SidebarMenuSubButton>
                </SidebarMenuSubItem>
              ))}
            </SidebarMenuSub>
          </CollapsibleContent>
        )}
      </SidebarMenuItem>
    </Collapsible>
  );
}

export function AppSidebar__Content() {
  const {
    mainNav,
    settingsNav,
    reportsNav,
    // channelsNav,
    helpCentersNav,
    // aiEmployeeNav,
    workflowsNav,
  } = useNavLinks();

  const [expandedSection, setExpandedSection] = useState<MainSection | null>(
    null,
  );

  return (
    <SidebarContent>
      <SidebarGroup>
        {/* <SidebarMenu>
        </SidebarMenu> */}
        <AppSidebar__Content__Cmdk />
      </SidebarGroup>

      <SidebarGroup>
        <SidebarGroupLabel>Apps</SidebarGroupLabel>
        <SidebarMenu>
          {mainNav.map((app) => (
            <AppSidebar__Content__MenuItem
              key={app.id}
              app={app}
              noSubItems={app.sections.length === 0}
              expandedSection={expandedSection}
              setExpandedSection={setExpandedSection}
            />
          ))}
        </SidebarMenu>
      </SidebarGroup>

      <SidebarGroup className="mt-auto">
        {/* Only show Wrapped 2024 for whitelisted orgs
        {id && ALLOWED_ORG_IDS.includes(id) && (
          <Link
            href="/wrapped-2024"
            className={cn(
              "block mb-4 transition-all duration-300",
              state === "collapsed" ? "px-2" : "pr-4",
            )}
          >
            <div className="relative group">
              <div className="absolute -inset-0.5 bg-[#ffb200] rounded-xl opacity-50 group-hover:opacity-75 blur transition duration-300" />
              <img
                src="/2024-wrapped.png"
                alt="2024 Wrapped"
                className={cn(
                  "relative w-full rounded-xl border border-[#ffb200]/50",
                  state === "collapsed" && "aspect-square object-cover",
                )}
              />
            </div>
          </Link>
        )} */}

        <SidebarMenu>
          {/* <AppSidebar__Content__MenuItem app={aiEmployeeNav} /> */}
          <AppSidebar__Content__MenuItem
            app={reportsNav}
            expandedSection={expandedSection}
            setExpandedSection={setExpandedSection}
          />
          {/* <AppSidebar__Content__MenuItem app={channelsNav} /> */}
          <AppSidebar__Content__MenuItem
            app={helpCentersNav}
            expandedSection={expandedSection}
            setExpandedSection={setExpandedSection}
            noSubItems
          />
          <AppSidebar__Content__MenuItem
            app={workflowsNav}
            expandedSection={expandedSection}
            setExpandedSection={setExpandedSection}
            noSubItems
            flag="beta"
          />
          <AppSidebar__Content__MenuItem
            app={settingsNav}
            expandedSection={expandedSection}
            setExpandedSection={setExpandedSection}
            noSubItems
          />
        </SidebarMenu>
      </SidebarGroup>
    </SidebarContent>
  );
}
