import(/* webpackMode: "eager", webpackExports: ["AppSidebar"] */ "/app/apps/dashboard/app/_components/AppSidebar/AppSidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Cmdk"] */ "/app/apps/dashboard/app/_components/Cmdk/Cmdk.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HandleUrlCopy"] */ "/app/apps/dashboard/app/_components/HandleUrlCopy.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SelectOrganization"] */ "/app/apps/dashboard/app/_components/SelectOrganization.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NotificationsProvider"] */ "/app/apps/dashboard/app/_data-providers/NotificationProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OrgProvider"] */ "/app/apps/dashboard/app/_data-providers/OrgDataProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SocketProvider"] */ "/app/apps/dashboard/app/_data-providers/SocketProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-runtime-env@3.2.2_next@15.2.3_react-dom@19.0.0_react@19.0.0__react@19.0.0__react@19.0.0/node_modules/next-runtime-env/build/provider/env-provider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-runtime-env@3.2.2_next@15.2.3_react-dom@19.0.0_react@19.0.0__react@19.0.0__react@19.0.0/node_modules/next-runtime-env/build/provider/use-env-context.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.2.3_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarProvider","SidebarInset"] */ "/app/packages/hoose/dist-separated/ui/sidebar.mjs");
